var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex"},[_c('div',{staticClass:"d-flex mr-auto"},[_c('b-card-text',{staticStyle:{"font-size":"16px"}},[_vm._v(" Click on date to check people's expense report ")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1)]),(_vm.rows.length > 0)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"search-options":{
            enabled: true,
            externalQuery: _vm.searchTerm,
        },"select-options":{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"pagination-options":{
            enabled: true,
            perPage: _vm.pageLength,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'date')?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":"Click to check report!"},on:{"click":function($event){return _vm.$router.push('/reports/expense/' + props.row.date)}}},[_vm._v(" "+_vm._s(_vm._f("monthYearFormat")(props.row.date))+" ")]):(props.column.field === 'attachment')?_c('span',[_c('b-avatar',{attrs:{"variant":"light-secondary"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){_vm.openPreview(
                                '/' +
                                    props.row.attachment
                                        .split('/')
                                        .slice(3, 10)
                                        .join('/')
                            )}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('View report'),expression:"'View report'",modifiers:{"hover":true,"bottom":true}}],attrs:{"icon":"EyeIcon","size":"16"}})],1)],1),_c('b-avatar',{staticClass:"ml-50",attrs:{"variant":"light-secondary"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){_vm.downloadPreview(
                                '/' +
                                    props.row.attachment
                                        .split('/')
                                        .slice(3, 10)
                                        .join('/')
                            )}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Download report'),expression:"'Download report'",modifiers:{"hover":true,"bottom":true}}],attrs:{"icon":"DownloadIcon","size":"16"}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"mt-43"},[_vm._v("Total: "+_vm._s(_vm.rows.length))]),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,3097259717)}):_c('div',[_c('b-alert',{attrs:{"variant":"secondary","show":""}},[_c('div',{staticClass:"alert-body mt-1",staticStyle:{"margin-bottom":"-14px"}},[_c('span',[_c('strong',[_vm._v("Sorry!")]),_vm._v(" No results found.")])])])],1),_c('b-modal',{attrs:{"id":"modal-preview","centered":"","title":"Report preview","ok-only":"","ok-title":"Close","size":"xl"}},[_c('b-card-text',[_c('div',{attrs:{"id":"tableHost"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }