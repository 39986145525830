<template>
    <b-card>
        <b-row>
            <b-col>
                <expenses-report-table />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BRow, BCol, BCard, VBTooltip } from "bootstrap-vue"
import ExpensesReportTable from "@/components/ExpensesReportTable.vue"

export default {
    name: "ExpensesReport",
    components: {
        ExpensesReportTable,
        BRow,
        BCol,
        BCard,
        VBTooltip,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
</script>
